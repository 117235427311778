import {
  LargeParagraph,
  Paragraph,
  Title,
} from '@atb-ventures/personas-components';
import styled from 'styled-components';
import { device } from 'theme';

export const HeroSubtitle = styled(LargeParagraph)`
  color: ${(props) => props.theme.colors.black};
`;

export const HeroSection = styled.section`
  display: flex;
  align-items: center;
  padding-left: 40px;
  position: relative;

  @media ${device.small} {
    flex-direction: column-reverse;
    align-items: flex-end;
    img {
      position: absolute;
      right: 0;
      top: 0;
      width: 40%;
    }
    div {
      margin-top: 30%;
    }

    ${Title}, ${HeroSubtitle} {
      width: 60%;
    }
  }
`;

export const HeroBody = styled(Paragraph)`
  color: #6d7072;

  @media ${device.medium} {
    padding-right: 4px;
  }
`;

export const ParagraphContainer = styled.div`
  margin: 0 auto;
  padding: 40px;
  max-width: 80%;

  @media ${device.small} {
    max-width: 100%;
  }
`;

export const TileContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 60px;
  padding-bottom: 30px;
  min-height: 535px;
  .overviewTile {
    justify-content: space-between;
  }

  @media ${device.medium} {
    flex-direction: column;
    margin: 30px 30px 0 30px;
    .overviewTile {
      min-height: 410px;
      padding: 24px;
      justify-content: space-between;
      border: none;
      box-shadow: none;
    }

    @media ${device.medium} {
      grid-template-columns: 1fr;
      margin: 30px 30px 0 30px;
      .overviewTile {
        min-height: 410px;
        padding: 24px;
        justify-content: space-between;
        border: none;
        box-shadow: none;
      }
      .overviewTile:hover {
        transform: translateY(0px);
      }
    }
  }
`;

export const StickyContentContainer = styled.div`
  .wellnessCategoriesContainer > div:nth-child(2n) {
    flex-direction: row-reverse;

    @media ${device.small} {
      flex-direction: column;
    }
  }
`;

export const FooterNavigationContainer = styled.div`
  margin-top: 80px;
  margin-bottom: 60px;
  padding: 40px;

  & > * {
    display: block;
    max-width: 600px;
    margin: 0 auto;
  }
`;
