import styled from 'styled-components';
import { device } from 'theme';

export const ContextualShiftsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  padding: 0 30px;

  @media ${device.medium} {
    grid-template-columns: 1fr;
    padding: 20px 0;
  }
`;

export const ContextualShiftItem = styled.div`
  padding: 20px;

  @media ${device.small} {
    padding: 20px 0;
  }
`;
