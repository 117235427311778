import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Link as PersonasLink, CTAProps } from '@atb-ventures/personas-components';
import { device } from 'theme';

const TileContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px;
  margin: 10px;
  text-decoration: none;
  color: inherit;

  @media ${device.smallOnly} {
    margin: 20px;
  }
  /* Greys/White */

  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease-out;

  &:hover {
    cursor: pointer;
    transform: translateY(-10px);
  }
`;

const Subtitle = styled.h3`
  /* font-family: Open Sans; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #6d7072;
`;

const Title = styled.h2`
  font-family: Ubuntu;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.01em;
  text-align: left;
`;

const Body = styled.p`
  /* font-family: Open Sans; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
`;

export interface TileProps {
  icon?: any;
  hoverIcon?: any;
  subtitle?: string;
  title?: string;
  body?: string;
  cta?: CTAProps;
  className?: string;
}

export const IntroTile: FC<TileProps> = ({
  icon,
  hoverIcon,
  subtitle,
  title,
  body,
  cta,
  className = '',
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <TileContainer
      className={className}
      to={cta?.link || ''}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? hoverIcon || icon : icon}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {title && <Title>{title}</Title>}
      {body && <Body>{body}</Body>}
      {cta && <PersonasLink as="p">{cta.title}</PersonasLink>}
    </TileContainer>
  );
};

export default IntroTile;
