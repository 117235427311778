import { MediumTitle, Paragraph } from '@atb-ventures/personas-components';
import React, { FC } from 'react';
import decayIdeogram from '@atb-ventures/personas-components/lib/assets/decay-ideogram.svg';
import growthIdeogram from '@atb-ventures/personas-components/lib/assets/growth-ideogram.svg';
import baselineIdeogram from '@atb-ventures/personas-components/lib/assets/baseline-ideogram.svg';
import equilibriumIdeogram from '@atb-ventures/personas-components/lib/assets/equilibrium-ideogram.svg';
import {
  ContextualShiftItem,
  ContextualShiftsContainer,
} from './context-shifts.style';

export const ContextualShifts: FC<{}> = () => (
  <ContextualShiftsContainer>
    <ContextualShiftItem>
      <img src={baselineIdeogram} />
      <MediumTitle>Baseline</MediumTitle>
      <Paragraph>
        This scenario highlights a future which stays status quo with current
        trend trajectories.
      </Paragraph>
    </ContextualShiftItem>
    <ContextualShiftItem>
      <img src={growthIdeogram} />
      <MediumTitle>Growth</MediumTitle>
      <Paragraph>
        This scenario demonstrates a future where harmonized efforts form an
        improved global condition.
      </Paragraph>
    </ContextualShiftItem>
    <ContextualShiftItem>
      <img src={decayIdeogram} />
      <MediumTitle>Decay</MediumTitle>
      <Paragraph>
        This scenario outlines a future where instability is the only guarantee.
      </Paragraph>
    </ContextualShiftItem>
    <ContextualShiftItem>
      <img src={equilibriumIdeogram} />
      <MediumTitle>New Equilibrium</MediumTitle>
      <Paragraph>
        This scenario outlines a future where progress is enabled by technology
        and accelerated by society.
      </Paragraph>
    </ContextualShiftItem>
  </ContextualShiftsContainer>
);
