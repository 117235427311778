import React, { FC } from 'react';
import SEO from 'components/seo';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import StickyScrollLayout from 'containers/personas/sticky-scroll-layout';
import {
  GenericContent,
  Paragraph,
  Title,
} from '@atb-ventures/personas-components';
import introImage from '../../assets/images/personas-intro.png';
import innateNatureIdeogram from '@atb-ventures/personas-components/lib/assets/innate-nature-ideogram.svg';
import innateNatureHoverIdeogram from '@atb-ventures/personas-components/lib/assets/innate-nature-hover-ideogram.svg';
import contextShiftsIdeogram from '@atb-ventures/personas-components/lib/assets/contextual-shifts-ideogram.svg';
import contextShiftsHoverIdeogram from '@atb-ventures/personas-components/lib/assets/contextual-shifts-hover-ideogram.svg';
import peopleOfTomorrowIdeogram from '@atb-ventures/personas-components/lib/assets/people-of-tomorrow-ideogram.svg';
import peopleOfTomorrowHoverIdeogram from '@atb-ventures/personas-components/lib/assets/people-of-tomorrow-hover-ideogram.svg';
import {
  FooterNavigationContainer,
  HeroBody,
  HeroSection,
  HeroSubtitle,
  ParagraphContainer,
  StickyContentContainer,
  TileContainer,
} from '../../containers/personas/humans-of-2030.style';
import wellnessCategoryImage from '../../assets/images/wellness-category.png';
import occupationalCategoryImage from '../../assets/images/occupational-category.png';
import socialCategoryImage from '../../assets/images/social-category.png';
import fitnessCategoryImage from '../../assets/images/fitness-category.png';
import financialCategoryImage from '../../assets/images/financial-category.png';
import { ContextualShifts } from 'containers/personas/context-shifts';
import { NavigationTile } from 'containers/personas/navigation-tile';
import IntroTile from 'containers/personas/intro-tiles';

const PersonasIndexPage: FC<{ location: string }> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Humans of 2030+" />
      <Header fixedDisplayVarient={true} />
      <main className="site-wrapper-reveal">
        <HeroSection>
          <div>
            <Title>Humans of 2030+</Title>
            <HeroSubtitle>
              Innate Nature + Contextual Shifts = People of Tomorrow
            </HeroSubtitle>
            <HeroBody>
              It would be impossible to build meaningful and trusted foundations
              for our future digital life without putting humans at the center
              of our work. As a team that is driving next-generation products
              and services, we believe that it is our responsibility to
              understand people’s preferences, concerns, attitudes, and wishes
              surrounding the future, and utilize these insights at the heart of
              work.
            </HeroBody>
          </div>
          <img src={introImage} alt="2030 Personas overview" />
        </HeroSection>
        <ParagraphContainer>
          <Paragraph>
            By doing so, we are ensuring that the future we are creating is not
            just ideal on a macro level, but also when it is distilled down to
            individual lived experiences. We are also positioning ourselves to
            better address the worries and struggles of humans as they
            transition from knowns of today into the unknowns of tomorrow.
          </Paragraph>
          <Paragraph>
            Similarly to human-centered design, ATB Ventures is exploring pain
            points of future people through our use of human-centered foresight.
            By marrying customer research with foresight methodologies, we were
            able to take a data-based approach to build a picture of how people
            may react and respond to emerging changes and what future people may
            look like.{' '}
          </Paragraph>
        </ParagraphContainer>
        <TileContainer>
          <IntroTile
            className="overviewTile"
            icon={
              <img
                src={innateNatureIdeogram}
                alt="innate nature visualization"
              />
            }
            hoverIcon={
              <img
                src={innateNatureHoverIdeogram}
                alt="innate nature visualization"
              />
            }
            title={'Innate Nature'}
            subtitle="01"
            body={
              'Through extensive primary research, we were able to define distinct user groups tied to variables that are unchanged under contextual shifts.'
            }
            cta={{
              title: 'Learn more',
              link: '#innate-nature',
            }}
          />
          <IntroTile
            className="overviewTile"
            icon={
              <img
                src={contextShiftsIdeogram}
                alt="contextual shifts visualization"
              />
            }
            hoverIcon={
              <img
                src={contextShiftsHoverIdeogram}
                alt="contextual shifts visualization"
              />
            }
            title={'Contextual Shifts'}
            subtitle="02"
            body={
              'Using foresight-driven contextual shifts, we mathematically modelled how these changes may affect future wellness.'
            }
            cta={{
              title: 'Learn more',
              link: '#contextual-shifts',
            }}
          />
          <IntroTile
            className="overviewTile"
            icon={
              <img
                src={peopleOfTomorrowIdeogram}
                alt="people of tomorrow visualization"
              />
            }
            hoverIcon={
              <img
                src={peopleOfTomorrowHoverIdeogram}
                alt="people of tomorrow visualization"
              />
            }
            title={'People of Tomorrow'}
            subtitle="03"
            body={
              'Combining innate nature and contextual shifts, we created 2030 Personas and gained an understanding of pain points that have yet to occur.'
            }
            cta={{
              title: 'Learn more',
              link: '/humans-of-2030/groups',
            }}
          />
        </TileContainer>
        <StickyContentContainer>
          <div id="innate-nature" />
          <StickyScrollLayout
            title="The Wellness Framework"
            subtitle="INNATE NATURE"
            childrenContainerClassName="wellnessCategoriesContainer"
            body="The primary framework leveraged for creating meaningful insights and pain points is a five category wellness framework. By utilizing these wellness categories as a frame of reference, it was possible to identify and probe key areas of an individual’s life, their pain points, and how their innate nature may influence their quality of life."
          >
            <GenericContent
              title={'Emotional'}
              body={
                'Emotional wellness refers to the ability to effectively manage feelings, stress, and life experiences.'
              }
              image={{
                src: wellnessCategoryImage,
                alt: 'Wellness framework image',
              }}
            />
            <GenericContent
              title={'Financial'}
              body={
                'Financial wellness refers to satisfaction with current and future financial circumstances.'
              }
              image={{
                src: financialCategoryImage,
                alt: 'Wellness framework image',
              }}
            />
            <GenericContent
              title={'Occupational'}
              body={
                'Occupational wellness refers to the personal satisfaction and enrichment individuals receive from their vocation.'
              }
              image={{
                src: occupationalCategoryImage,
                alt: 'Wellness framework image',
              }}
            />
            <GenericContent
              title={'Physical'}
              body={
                'Physical wellness refers to maintaining healthy routines and seeking necessary care and support when needed.'
              }
              image={{
                src: fitnessCategoryImage,
                alt: 'Wellness framework image',
              }}
            />
            <GenericContent
              title={'Social'}
              body={
                'Social wellness refers to having a strong support system, a sense of connection, and a sense of belonging.'
              }
              image={{
                src: socialCategoryImage,
                alt: 'Wellness framework image',
              }}
            />
          </StickyScrollLayout>
        </StickyContentContainer>
        <div id="contextual-shifts" />
        <StickyScrollLayout
          title="The Contextual Shifts"
          subtitle="Contextual"
          body={
            'The contextual shifts are based on ATB Ventures’ Scenarios of 2030 which were crafted from extensive horizon scanning. The scenarios highlight four vastly different futures and are based on the archetype method of scenario creation.\n\nLearn more about our scenarios through our whitepaper.'
          }
        >
          <ContextualShifts />
        </StickyScrollLayout>
        <FooterNavigationContainer>
          <NavigationTile
            icon={peopleOfTomorrowIdeogram}
            title={'People of Tomorrow'}
            link={{
              title: 'Explore groups',
              link: '/humans-of-2030/groups',
            }}
          />
        </FooterNavigationContainer>
      </main>
      <Footer />
    </Layout>
  );
};

export default PersonasIndexPage;
