import {
  MediumTitle,
  MetaHeading,
  Paragraph,
} from '@atb-ventures/personas-components';
import React, { FC } from 'react';
import { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { device } from 'theme';

const StickyScrollContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 100px 40px;

  h2,
  h3,
  p {
    margin: 12px 0;
  }
  h3 {
    text-transform: uppercase;
  }

  @media ${device.medium} {
    display: flex;
    flex-direction: column;
    padding: 50px 40px;
  }
`;

const ContentContainer = styled.div`
  // width: 50%;
`;

const StickyContainer = styled.div`
  position: sticky;
  top: 116px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: block;

  @media ${device.medium} {
    display: none;
  }
`;

const MobileStickyContainer = styled.div`
  display: none;

  @media ${device.medium} {
    display: block;
    position: sticky;
    padding-top: 20px;
    padding-bottom: 30px;
    top: 63px;
    z-index: 100;
    background: ${(props) => props.theme.colors.white};
  }
`;

const MobileTextContainer = styled.div`
  display: none;
  @media ${device.medium} {
    display: block;
  }
`;

const UnstickyColumn = styled.div`
  transform: translateY(80px);

  @media ${device.medium} {
    transform: none;
  }
`;

export interface StickyScrollLayoutProps {
  title: string;
  subtitle: string;
  body: string;
  children: ReactElement | ReactElement[];
  childrenContainerClassName?: string;
}

export const StickyScrollLayout: FC<StickyScrollLayoutProps> = ({
  title,
  subtitle,
  body,
  childrenContainerClassName,
  children,
}) => {
  return (
    <StickyScrollContainer>
      <MobileStickyContainer>
        <MetaHeading>{subtitle}</MetaHeading>
        <MediumTitle>{title}</MediumTitle>
      </MobileStickyContainer>
      <MobileTextContainer>
        <Paragraph>
          <ReactMarkdown>{body}</ReactMarkdown>
        </Paragraph>
      </MobileTextContainer>
      <ContentContainer>
        <StickyContainer>
          <MetaHeading>{subtitle}</MetaHeading>
          <MediumTitle>{title}</MediumTitle>
          <Paragraph>
            <ReactMarkdown>{body}</ReactMarkdown>
          </Paragraph>
        </StickyContainer>
      </ContentContainer>
      <UnstickyColumn className={childrenContainerClassName}>
        {children}
      </UnstickyColumn>
    </StickyScrollContainer>
  );
};

export default StickyScrollLayout;
